<template>
  <div>
    <div class="d-lg-flex">
      <v-combobox
          :items="partnerVillages"
          v-model="formFields.village"
          item-text="village"
          return-object
          :label="'Village'"
          class="mr-6"
      ></v-combobox >

      <v-combobox
          :items="wardOptions"
          :label="'Ward'"
          v-model="formFields.ward"
          class="mr-6"
      ></v-combobox >
    </div>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      selectedVillage: null,
      wardOptions : []
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.formFields = to;
      }
    },
    'formFields.village': function(newValue) {
      this.selectedVillage = newValue
      this.updateWardList()
    }
  },

  methods: {
    updateWardList() {
      this.wardOptions = Object.keys(this.partnerRawRegions[this.selectedVillage].wards)
    }
  },

  computed: {
    partnerRawRegions() {
      return this.$store.getters.settings.regions
    },
    partnerVillages() {
      return Object.keys(this.partnerRawRegions)
    }
  }
}
</script>

<style scoped>

</style>
